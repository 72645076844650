export default {
  url: 'https://gpsapinewstage.qsstechnosoft.com/graphql?',
  url1: 'https://gpsapioldstage.qsstechnosoft.com/graphql?',
  urlCkEditor: 'https://gpsapinewstage.qsstechnosoft.com/api/containers/upload',

}

export const authHeader = () => {
  return ({
      headers: {
          "Content-Type": "application/json",
          "authorization": localStorage.getItem("token"),
          xcvtoken: localStorage.getItem("id")
      },
  })

}
